import React, { useState } from 'react';
import validator from 'validator';
import { navigate } from 'gatsby';
import Title from '../../components/Title';
import InputText from '../../components/InputText';
import Button from '../../components/Button';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import './style.sass';

const states = {
  type: null,
  company: '',
  name: '',
  email: '',
  message: '',
};

export default props => {
  const [errors, setErrors] = useState({
    type: null,
    company: null,
    name: null,
    email: null,
    message: null,
  });

  const handleSubmitButton = e => {
    e.preventDefault();

    const result = {
      type: null,
      company: null,
      name: null,
      email: null,
      message: null,
    };

    if (!states.type) {
      console.log('type');
      result.type = 'お問い合わせ内容をお選びください';
    }

    if (!validator.isByteLength(states.name, { min: 1, max: 100 })) {
      console.log('name');
      result.name = '1〜100文字以内での入力をお願いします';
    }

    if (!validator.isEmail(states.email)) {
      console.log('email');
      result.email = '入力形式に問題がありました';
    }

    if (!validator.isByteLength(states.company, { min: 0, max: 100 })) {
      console.log('company');
      result.company = '100文字以内での入力をお願いします';
    }

    if (!validator.isByteLength(states.message, { min: 1, max: 1000 })) {
      console.log('message');
      result.message = '1〜1000文字以内の入力をお願いします';
    }

    setErrors({ ...errors, ...result });
    if (!Object.values(result).every(item => item === null)) return;

    console.log(states);

    window
      .fetch('https://us-central1-pug-dev-1eb85.cloudfunctions.net/sendMail', {
        // .fetch('http://localhost:5000/pug-dev-1eb85/us-central1/sendMail', {
        method: 'POST',
        mode: 'no-cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          // "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify({
          type: states.type,
          company: states.company,
          name: states.name,
          email: states.email,
          message: states.message,
        }),
      })
      .then(response => {
        console.log(response);
        navigate('/contact/thanks');
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleChange = e => {
    states.type = e.currentTarget.value;
  };

  const handleInput = e => {
    states[e.currentTarget.name] = e.currentTarget.value;
  };

  return (
    <Layout>
      <Seo
        title="お問い合わせ"
        description="お問い合わせページです"
        href={props.location.href}
      />

      <Title>お問い合わせ</Title>

      <div className="contact">
        <form className="contact__form form">
          <div className="contact__label contact__label--required">
            お問い合わせ内容
          </div>

          {errors.type ? (
            <div className="contact__error">{errors.type}</div>
          ) : null}

          <div className="contact__item">
            <input
              id="r1"
              className="contact__radio form__radio"
              type="radio"
              name="type"
              defaultValue="1"
              onChange={handleChange}
            />
            <label htmlFor="r1">サービスについてのご質問</label>
            <input
              id="r2"
              className="contact__radio form__radio"
              type="radio"
              name="type"
              defaultValue="2"
              onChange={handleChange}
            />
            <label htmlFor="r2">お仕事のご依頼</label>
            <input
              id="r3"
              className="contact__radio form__radio"
              type="radio"
              name="type"
              defaultValue="3"
              onChange={handleChange}
            />
            <label htmlFor="r3">その他</label>
          </div>

          <label className="contact__label contact__label--optional">
            会社名
          </label>

          {errors.company ? (
            <div className="contact_error">{errors.company}</div>
          ) : null}

          <div className="contact__item">
            <InputText
              name="company"
              handleChange={handleChange}
              placeholder="キーワードを入力してください"
            />
          </div>

          <div className="contact__label contact__label--required">お名前</div>

          {errors.name ? (
            <div className="contact_error">{errors.name}</div>
          ) : null}

          <div className="contact__item">
            <InputText
              name="name"
              handleChange={handleChange}
              placeholder="キーワードを入力してください"
            />
          </div>

          <div className="contact__label contact__label--required">
            ご連絡先メールアドレス
          </div>

          {errors.email ? (
            <div className="contact_error">{errors.email}</div>
          ) : null}

          <div className="contact__item">
            <InputText
              name="email"
              handleChange={handleChange}
              placeholder="キーワードを入力してください"
            />
          </div>

          <div className="contact__label contact__label--required">
            お問い合わせ内容
          </div>

          {errors.message ? (
            <div className="contact_error">{errors.message}</div>
          ) : null}

          <div className="contact__item">
            <textarea
              className="form__textarea"
              name="message"
              onInput={handleInput}
            ></textarea>
          </div>

          <div className="contact__item contact__item--center">
            <button
              onClick={handleSubmitButton}
              className="form__submit contact__submit"
            >
              送信
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};
